.divider-light {
    width: 5pc;
    border-top: 5px solid black;
}

.divider-dark {
    width: 5pc;
    border-top: 5px solid rgb(255, 255, 255);
}

.bttn-light {
    width: 140px;
    padding: 10px 10px !important;
    font-size: 16px !important;
    font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif !important;
    color:#0f0e1e !important;
    background-color: #ffffff !important;
    border-radius:5px;
    border: none;
    transition: all 0.3s ease;
}

.bttn-light:hover {
    transform:scale(1.05)
}

.bttn-dark{
    width: 140px;
    padding: 10px 10px !important;
    font-size: 16px !important;
    font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif !important;
    color:#ffffff !important;
    background-color: #0f0e1e !important;
    border-radius: 5px;
    border: none;
    transition: all 0.3s ease;
}

.bttn-dark:hover {
    transform:scale(1.05)
}



