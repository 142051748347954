.horizontal-line-dark {
    width: 100%;
    border-top: 2px solid #e7e7e7;
}
.horizontal-line-light {
    width: 100%;
    border-top: 2px solid #0f0e1e;
}

/* About-Section styling  */
.content {
    font-size: 1.25rem;
    line-height: 1.625;

}



/* Technologies Section styling  */
.technologies{
    text-align: center;
}
/* Technologies Section styling  */  



/* Certificaiton section styling  */
.certifications {
text-align: center;
}


.certifications a {
    text-decoration: none;
    color:inherit;
}
.certifications ol {
    list-style: none;
}



/* stlying of different section - for large screens   */
@media (min-width: 992px) {
    .certifications ol {
     list-style: decimal;
    }
 
  .common-styling{
    text-align: start;
    margin-left: 10pc;
    margin-top: 2pc;
    }
}
