

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

.theme-dark {
    color: #0f0e1e;
    text-decoration: none;
    background-color: #e7e7e7;
    
}

.theme-light {
    color: #e7e7e7;
    text-decoration: none;
    background-color: #0f0e1e;
    
}